import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs';

@Component({
    selector: 'app-pagination',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        FaIconComponent,
    ],
    styles: `
      @use "variables" as *;

      :host {
        display: block;
        width: 100%;
        margin-top: auto;
      }

      .pagination {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eee;
        margin-top: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        color: $grey;
      }

      .arrows {
        cursor: pointer;
        border: none !important;
        
        &:hover {
          color: $red;
        }
      }

      .hide {
        opacity: 0;
        pointer-events: none;
      }
    `,
    template: `
        <div class="pagination">
            @if (currentPage() && availablePages() > 1) {
                <button class="arrows" (click)="toPage(currentPage()! - 1)" [class.hide]="currentPage()! <= 1">
                    <fa-icon [icon]="faArrowLeft" class="me-1"/>
                    Previous
                </button>
                <p class="number">Page {{ currentPage() }} of {{ availablePages() }}</p>
                <button class="arrows" (click)="toPage(currentPage()! + 1)"
                        [class.hide]="availablePages()! <= currentPage()!">
                    Next
                    <fa-icon [icon]="faArrowRight" class="ms-1"/>
                </button>
            }
        </div>
    `
})
export class PaginationComponent {
    public totalItems = input.required<number | undefined>();
    public pageSize = input.required<number | undefined>();
    public currentPage: Signal<number | undefined>;
    public availablePages = computed(() => Math.ceil((this.totalItems() ?? 1) / (this.pageSize() ?? 1)));
    public faArrowLeft = faArrowLeft;
    public faArrowRight = faArrowRight;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentPage = toSignal(this.route.queryParamMap.pipe(
            map(params => params.get('page') ?? 1),
            map(page => +page)
        ));
    }

    toPage(page: number) {
        this.router.navigate([], {
            queryParams: { page },
            queryParamsHandling: 'merge',
        });
    }

}
