import { CreateEffectOptions, effect, signal, Signal } from '@angular/core';
import { firstValueFrom, isObservable, Observable } from 'rxjs';

export function computedAsync<T>(
    cb: () => T | Observable<T> | Promise<T>,
    options: CreateEffectOptions
): Signal<T | undefined> {
    const val = signal<T | undefined>(undefined);

    effect(async () => {
        val.set(undefined);

        const value = cb();

        if (isObservable(value)) {
            val.set(await firstValueFrom(value, {defaultValue: undefined}));
            return;
        }

        if (value instanceof Promise) {
            val.set(await value);
            return;
        }

        val.set(value);
    }, { ...options });

    return val.asReadonly();
}
